// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chsfdlkjklj-544-js": () => import("./../../../src/pages/chsfdlkjklj544.js" /* webpackChunkName: "component---src-pages-chsfdlkjklj-544-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */),
  "component---src-pages-virtual-tour-js": () => import("./../../../src/pages/virtual-tour.js" /* webpackChunkName: "component---src-pages-virtual-tour-js" */),
  "component---src-templates-for-about-js": () => import("./../../../src/templates/for-about.js" /* webpackChunkName: "component---src-templates-for-about-js" */),
  "component---src-templates-vacancy-template-js": () => import("./../../../src/templates/vacancy.template.js" /* webpackChunkName: "component---src-templates-vacancy-template-js" */)
}

